.font {
  font-family: 'Kaushan Script', cursive !important;
}

body {
  overflow-x: hidden !important;
}

.main {
  background-image: url(../src/components/asset/images/mainBg.png);
  /* background-image: url(../src/components/asset/images/BG.png); */
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: #ffffff;
  /* padding: 70px 80px; */
}

.menu {
  font-size: 30px !important;
}

.khula {
  font-family: 'Khula', sans-serif !important;

}

.startBtn {
  border-radius: 86px !important;
  background-color: #7DC705 !important;
  color: #3d6000 !important;
  font-family: 'Khula', sans-serif !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  font-size: 25px !important;
  display: inline-flex !important;
  padding: 5px 15px !important;
  border: 0 !important;
}

.arrowicon {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50%;
  background-color: #3d6000;
  color: #7DC705;
  margin-left: 6px;
  margin-top: 3px;
}

.seemore {
  border-radius: 86px !important;
  background-color: #7DC705 !important;
  color: #ffffff !important;
  font-family: 'Khula', sans-serif !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  font-size: 20px !important;
  display: inline-flex !important;
  padding: 3px 10px !important;
  border: 0 !important;
}

.arrowicon1 {
  height: 25px !important;
  width: 25px !important;
  border-radius: 50%;
  background-color: #ffffff;
  color: #7DC705;
  margin-left: 6px;
  margin-top: 3px;
}


.aboutimg {
  height: 100%;
  width: 100%;
}

.aboutimg2 {
  height: 100%;
  width: 100%;
}

.clientBox {
  background-color: #D9D9D9;
  height: 180px;
  width: 180px;
  border-radius: 50%;
}

.servicesImg {
  position: absolute;
  bottom: 0;
  top: -40px;
  width: 40% !important;
  left: -14px;
}

.footer {
  background-color: #e3e1e4;
  padding: 30px;
  border-radius: 86px;
  color: #4e4d78;
}

/* .serviceWraper {
  background-image: url(../src/components/asset/images/servicebg.png);
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  height: 1414px !important;
}

.aboutWraper {
  background-image: url(../src/components/asset/images/aboutbg2.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.headerBg {
  background-image: url(../src/components/asset/images/header.png);
  background-position: center;
  width: 100%;
  position: absolute;

} */

/* @media (max-Width:2560px) {
  .serviceWraper {
    height: 1414px !important;
  }
}

@media (max-Width:1440px) {
  .serviceWraper {
    height: 1190px !important;
  }
} */


@media (max-Width:1200px) {
  /* .serviceWraper {
    height: 730px !important;
  } */

  .servicesImg {
    position: static;
    width: 100% !important;
    height: 90%;
  }
}

/* @media (max-Width:992px) {
  .serviceWraper {
    height: 1870px !important;
  }
} */

@media (max-Width:768px) {
  /* .serviceWraper {
    height: 1444px !important;
  } */

  .servicesImg {
    position: static;
    width: 100% !important;
  }

  .aboutimg {
    height: 80%;
    width: 80%;
  }

  .aboutimg2 {
    height: 80%;
    width: 80%;
  }

  .startBtn {
    margin-top: 10px;
    font-size: 15px !important;
    padding: 3px 10px !important;
  }

  .arrowicon {
    height: 18px !important;
    width: 18px !important;
    margin-left: 6px;
    margin-top: 4px;
  }

  .seemore {
    font-size: 16px !important;
    padding: 6px 10px 5px 10px !important;
  }

  .arrowicon1 {
    height: 18px !important;
    width: 18px !important;
  }
}

/* @media (max-Width:576px) {
  .serviceWraper {
    height: 1010px !important;
  }
}

@media (max-Width:455px) {
  .serviceWraper {
    height: 880px !important;
  }
}

@media (max-Width:350px) {
  .serviceWraper {
    height: 640px !important;
  }
} */